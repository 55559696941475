export const SingleHome = () => {
    return (
        <div className="homeSinglePage h-screen w-screen flex items-center justify-center bg-hero-blockchain">
            <div className="text-white md:max-w-[70%]">
                <p className="text-[42px] text-center font-montserrat font-medium leading-[3rem] py-4 mx-[10%] md:mx-[0%] md:py-6 sm:text-[57px]">
                    The future of real estate tokenizer
                </p>
                <p className="text-center font-montserrat font-medium mx-2 md:mx-0">
                    Democratize and make real estate investment accessible all over the world from just a few dozen dollars and through the blockchain... ⏳
                    {/* Démocratiser et rendre accessible l’investissement immobilier partout dans le monde à partir de seulement quelques dizaines d’euros et à travers la blockchain. */}
                </p>
            </div>
        </div>
    );
}

